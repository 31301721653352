<template>
  <div>
    
    <!-- Filter options checkbox: Station, Fail, Pass
    Order by
    Search
    Limit items per page -->
    <div class="lds-dual-ring" v-if="loading && !error"></div>
    <div v-if="error" class="error"><AlertCircleOutline/> Flows not found</div>
    
    <div v-if="!loading" class="content">
      <div class="bannertitle" >Manage flows</div>

      <button class="button" v-on:click="createFlow">Create flow</button>

      <ag-grid-vue
          style="width: 1524px; margin-top: 10px"
          class="ag-theme-alpine"
          domLayout="autoHeight"
          :columnDefs="columnDefs"
          :rowData="flows"
          :pagination="true"
          @rowClicked="onRowClicked"
          @rowDoubleClicked="onRowDoubleClicked"
          v-if="!loading"
        >
        </ag-grid-vue>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import Table from "@/components/Table"

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
  name: 'Flows',
  components: {
    // Table,
    AlertCircleOutline,
    AgGridVue
  },
  methods: {
		onRowClicked(params) {
			window.onmousemove = function(e) {
				if (!e) e = window.event;
				if (e.altKey) {
					navigator.clipboard.writeText(
						params.node.data.TestResult.leftSN + "	" + params.node.data.TestResult.rightSN + "	" + params.node.data.TestResult.cradleSN
					);
				}
			};
		},
		onRowDoubleClicked(params) {
			let route = this.$router.resolve({ path: params.node.data.url });
			window.open(route.href);
		},
    createFlow(){
      var newFlow = {}
      newFlow.flowID = ""
      newFlow.name = "New flow";
      newFlow.allowedModels = [];
      newFlow.nodes = [];

      this.axios.post('/flow/create', newFlow)
        .then(response => {
            if(response.data.error != null){
              this.error = response.data.error;
              document.getElementById("error").style.opacity = '1';
            }
            else{
              this.$router.push({ path: `/flow/${response.data.flowID}` })
              this.$router.go()
            }
        })
        .catch(err => {
            console.error(err);
      });
    },
	},
  data() {
    return{
      stations: [],
      loading: true,
      error: false,
      flows: []
    }
  },
  mounted() {
      this.axios
      .get('/flows')
      .then(response => {
          this.flows = response.data;
          for (var i = 0; i < this.flows.length; i++) {
            this.flows[i].url = 'flow/'+this.flows[i].flowID;
          }
          this.loading = false
      })
      .catch(err => {
          this.error = true;
      });
  },
  beforeMount() {
		const tagRender = (params) => `<div class="tag ${params.value}">${params.value}</div></td>`;
		const dateRender = (params) => `${new Date(params.value).toLocaleString("nl-NL")}`;

		function dateComparator(date1, date2) {
			const date1Number = new Date(date1).getTime();
			const date2Number = new Date(date2).getTime();
			if (date1Number === null && date2Number === null) {
				return 0;
			}
			if (date1Number === null) {
				return -1;
			}
			if (date2Number === null) {
				return 1;
			}
			return date1Number - date2Number;
		}

		this.columnDefs = [
			{ field: "name", sortable: true, filter: true, headerName: "Flow name", width: 1020 },
		];
	},
}
</script>
<style scoped>
.tag{
  display: inline-block;
  margin-top: 5px;
}

.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 25px;
}

.FAIL{
  background-color: #bd7171;
  min-width: 50px;
}

.PASS {
  background-color: #67995b;
  min-width: 50px;
}

</style>
