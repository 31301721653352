<template>
   <div class="step"> 
     
      <div class="steptitle">
        <input class="steptitleBox" v-bind:style="'width:'+step.description+'ch'" 
        v-bind:value="step.description" 
        @change="stepDescChanged($event)" 
        maxlength="64"
        :disabled="!enabled"/>

        <button v-on:click="deleteStep()" class="rightbutton" :disabled="!enabled"> 
            <span class="buttonicon">
              <Close/>
            </span>
        </button>
        <button v-on:click="add()" class="rightbutton" :disabled="!enabled"> 
            <span class="buttonicon">
              <PlaylistPlus/>
            </span>
        </button>
      </div>
      <div v-if="step.actions.length == 0" class="noactions">This step is empty <br>  
        <button v-on:click="add()" class="actionbutton" :disabled="!enabled"> 
            <span class="buttonicon">
              <PlaylistPlus/> Add action
            </span>
        </button>
      </div>
      <draggable v-model="step.actions" @start="drag=true" @end="drag=false" @change="actionOrderChanged" :options="{disabled : !enabled}">
        <div class="substep" v-for="(action, actionIndex) in step.actions" :key="step.stepNR + '-' + action.actionNR" :set="params={param: action.parameters.split(','), desc: action.action.parameterDescription.split(',')}"> 
          <Drag draggable="true"/>
          <div class="actionnr">{{action.actionNR+1}}</div> 
          {{action.action.methodName.split('.')[0]}} <chevron-right-icon/> 

          {{action.action.methodName.split('.')[1]}} <chevron-right-icon  v-if="action.action.parameterCount > 0" /> 
          
          <div class="params" v-if="action.action.parameterCount > 0" >
            <div v-for="(param, index) in params.desc" v-bind:key="index" class="argumentDesc">
                {{params.desc[index]}} = <input class="argumentValue" v-bind:style="'width:5ch'" v-bind:value="params.param[index]" @change="argumentChanged($event, actionIndex, index)" @input="argumentResize($event)" :disabled="!enabled"/>
            </div>
          </div>
          <button v-on:click="deleteAction(actionIndex)" class="rightbutton" :disabled="!enabled"> 
            <span class="buttonicon">
              <Close/>
            </span>
          </button>
          <button v-on:click="openDialog($event)" class="rightbutton">
            <input v-on:change="uploadImage($event, actionIndex)" style="display: none;" type="file"/>
            <span class="buttonicon">
              <Upload/>
            </span>
          </button>
            <a :href=action.image target="_blank" class="rightbutton" v-if="action.image != ''">
            <span class="buttonicon">
              <Img/>
            </span>
          </a>
        </div>
      </draggable>
    </div>
</template>

<script>
    import Close from 'vue-material-design-icons/Close.vue'
    import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
    import PlaylistPlus from 'vue-material-design-icons/PlaylistPlus.vue'
    import Drag from 'vue-material-design-icons/Drag.vue'
    import Upload from 'vue-material-design-icons/Upload.vue'
    import Img from 'vue-material-design-icons/Image.vue'

    import draggable from 'vuedraggable'

    export default {
        name: "EditTestStepItem",
        components: {
            ChevronRightIcon,
            Close,
            PlaylistPlus,
            Drag,
            draggable,
            Upload,
            Img
        },
        props: {
            step: [],
            enabled: Boolean
        },
        methods: {
          add: function(){
            this.$emit('showActionModal', this.step.stepNR)
          },
          deleteAction: function(actionIndex){
            this.$emit('deleteAction', this.step.stepNR, actionIndex)
          },
          deleteStep: function(){
              this.$emit('deleteStep', this.step.stepNR)
          },
          actionOrderChanged: function(evt){
              this.$emit('actionOrderChanged', evt)
          },
          argumentChanged: function(evt, actionIndex, paramIndex){
            var argument = evt.target.value;
            evt.target.blur();
            this.$emit('argumentChanged', this.step.stepNR, actionIndex, paramIndex, argument);
          },
          argumentResize: function(evt){
             evt.target.style.width = evt.target.value.length + "ch";
          },
          stepDescChanged: function(evt){
            var desc = evt.target.value;
            evt.target.blur();
            this.$emit('stepDescChanged', this.step.stepNR, desc);
          },
          uploadImage: function(event, actionIndex){
            const file = event.target.files[0];
            const reader = new FileReader();
            
            let rawImg;
            reader.onloadend = () => {
              rawImg = reader.result;
              console.log(rawImg);
              this.$emit('uploadImage', this.step.stepNR, actionIndex, rawImg)
            }
            reader.readAsDataURL(file);
          },
          openDialog: function(event){
            event.target.querySelector('input[type=file]').click();
          }
          
        }
    }
</script>

<style scoped>

.desc{
  margin-top: 5px;
}
.params{
  display: inline-block;
}

.noactions{
  text-align: center;
}

.steptitleBox{
  display: inline-block;
  background-color:  transparent;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 0px;
  width: 94%;
  height: 6px;
  border-color: transparent;
  padding: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}

.steptitleBox:hover{
  border-color: #cfd6e6;
}

.argumentValue{
  display: inline-block;
  background-color:  #78a583;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 0px;
  height: 10px;
  -webkit-transition-duration: .0s;
  transition-duration: .0s;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.argumentDesc{
  display: inline-block;
  background-color:  #475663;
  padding-left: 5px;
  border-radius: 3px;
  margin-left: 5px;
}

.actionnr{
  display: inline-block;
  background-color:  #475663;
  padding: 3px;
  border-radius: 2px;
}
.rightbutton{
  float: right;
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  position: relative;
}

.rightbutton:hover {
    background-color: #9c9c9c;
    cursor: pointer;
    -webkit-transition: background-color 70ms linear;
    -moz-transition: background-color 70ms linear;
    -o-transition: background-color 70ms linear;
    transition: background-color 70ms linear;
}

.actionbutton{
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  margin-top: 5px;
}

.actionbutton:hover {
    background-color: #9c9c9c;
    cursor: pointer;
    -webkit-transition: background-color 70ms linear;
    -moz-transition: background-color 70ms linear;
    -o-transition: background-color 70ms linear;
    transition: background-color 70ms linear;
}

.buttonicon{
    pointer-events: none;
}
.step{
  display: inline-block;
  background-color: #475663;
  color: white;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 2px;
}
.steptitle{
    margin-bottom: 5px;
    margin-left: 2px;
    font-weight: bold;
}
.substep{
    display: inline-block;
    background-color: #5c7080;
    color: white;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    margin-bottom: 2px;
}

</style>