<template>
  <div>
      <div class="modal" id="modal" v-if="visible" v-on:click="close()">
      <div class="modal-content" v-on:click.stop>
        <button v-on:click="close()" class="rightbutton"> 
            <span class="buttonicon">
              <Close/>
            </span>
        </button>
         <div class="label">{{desc}}</div>
         <div class="item">
          <div class="desc">Enter the modelid (MID) with or without color code. For example: <code>DP005E</code> or <code>DP005E_001</code></div>
          <input type="text" name="model" placeholder="DP000XX" v-model="model.model" v-on:input="change()"> <br>
          <div class="desc">Model specific variables can be defined by typing <code>$</code> followed by the variable name. For example <code>$MIN_VERSION</code></div>
          <textarea name="variables" placeholder="$EXAMPLE=1.0.0" spellcheck="false" v-model="model.variables" v-on:change="change()"></textarea> <br>
        </div>
        <button class="button close" @click="close()">Close</button>
        <button class="button del" @click="del()"><Delete/> Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import Close from 'vue-material-design-icons/Close.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
    export default {
        name: "Modal",
        components: {
          Close,
          Delete
        },
        props: {
            desc: String,
            model: Object,
            type: String,
            visible: Boolean
        },
        data(){
          return{
          }
        },
         methods: {
            del: function(id){
              this.$emit('delete')
            },
            close: function(){
              this.$emit('closed')
            },
            change: function(){
              this.$emit('changed')
            }
         }
  }
</script>

<style scoped>

.close{
  margin-left: 5px;
  background-color: #475663;
  float: right;
  margin-top: 5px;
}
.del {
  float: right;
  margin-top: 5px;
  background-color: rgb(194, 58, 58);
}
.modal {
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); 
}
.modal-content {
  background-color: #7994a8;
  margin: 15% auto;
  border-radius: 2px;
  width: 500px;
}
.label{
    color: white;
    font-weight: bold;
    text-align: left;
    padding: 10px;
}
.desc{
    color: white;
    text-align: left;
}
.item{
  background-color: #475663;
  color: white;
  padding: 10px;
}
.list{
  margin-top: 10px;
}
.rightbutton{
  float: right;
  margin-right: 5px;
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  position: relative;
}

input{
  width: 465px;
}

textarea{
  padding: 7px;
  border-radius: 3px;
  color: #5c616c;
  background-color: #fff;
  border: 1px solid #cfd6e6;
  margin-top: 5px;
  width: 465px;
  min-height: 250px;
}

</style>