<template>
  <div>
     <input v-model="search" placeholder="Search">
     <div class="error" v-if="rows().length == 0"><AlertCircleOutline/> No results for '{{this.search}}'</div>
       <table v-if="rows().length > 0">
        <thead>
          <tr>
            <th v-for="column in columns" v-bind:key="column">
              <a href="#">
                {{ column }}
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <router-link :to="row.url" custom v-slot="{ navigate }" v-for="(row, index) in rows()" v-bind:key="index">
          <tr @click="navigate">
           <slot :row="row"></slot>
          </tr>
          </router-link>
        </tbody>
      </table>

  </div>
</template>

<script>
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
    export default {
        name: "Table",
        components: {
         AlertCircleOutline
        },
        props: {
            columns: [],
            items: []
        },
        data(){
          return{
            search: '',
            show: true
          }
        },
         methods: {
            rows: function() {
              var newlist = [];
              for (var i = 0; i < this.items.length; i++) {
                for(var field in this.items[i]){
                  var tmp = String(this.items[i][field]).toLowerCase();
                  if(tmp.includes(this.search.toLowerCase())){
                      newlist.push(this.items[i]);
                      break;
                  }
                }
              }
            return newlist;
            }
         }
  }
</script>

<style scoped>
input{
  width: 100%;
  box-sizing: border-box; 
}

table{
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  margin-top: 10px;
}

th a{
  color: #ebebeb;
}

th{
  background-color: #5c616c;
  padding: 10px;
  border-right: 1px solid #50545e;
}

th:first-of-type{
  border-top-left-radius: 3px;
}

th:last-of-type{
  border-top-right-radius: 3px;
}

td{
  color: #353535;
}

td:first-of-type{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

td:last-of-type{
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

tr{
  cursor: pointer;
  height: 30px;
}

tr:nth-child(even) {background: #e4e4e4}
tr:nth-child(odd) {background: #fafafa}
</style>