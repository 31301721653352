<template>
 <div>
    <div class="lds-dual-ring" v-if="loading && !error"></div>
    <div v-if="error" class="error" id="login_error"><AlertCircleOutline/> Stations not found</div>
    
  <div class="banner" v-if="!loading">
    <div class="bannercontent">
        <div class="logo"></div>
        <div class="bannertitle">Edit '{{station.name}}'</div>
        <div class="bannerdesc"><div class="tag">{{station.status}}</div></div>
        <button disabled class="button save" v-on:click="save">Changes saved</button>
    </div>
  </div>

  <div class="content" v-if="!loading">
    <div class="label">Name</div>
    <input type="text" placeholder="Name" :value="station.name" v-on:keyup="onChangeName($event)"/>
    <br>
    <div class="info"><AlertCircleOutline/> Name will be updated after testing is complete</div>
  </div>
 </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'

export default {
  name: 'Station',
  components: {
    AlertCircleOutline
  },
   data() {
    return{
      station: [],
      loading: true,
      error: false
    }
  },
    mounted() {
      this.axios
      .get('/station/'+this.$route.params.id)
      .then(response => {
          this.station = response.data;
          this.loading = false
      })
      .catch(err => {
          this.error = true;
      });
  },
  methods: {
        onChange(event) {
            var button = document.getElementsByClassName("save")[0];
            button.disabled = false;
            button.innerHTML = "Save changes";
        },
         onChangeName(event) {
            var button = document.getElementsByClassName("save")[0];
            button.disabled = false;
            button.innerHTML = "Save changes";
            this.station.name = event.target.value;
        },
         save: function (event) {
          this.axios.post('/station/'+this.station.stationID+'/edit', this.station)
          .then(response => {
              if(response.data.error != null){
                this.error = response.data.error;
                document.getElementById("login_error").style.opacity = '1';
              }
              else{
                var button = document.getElementsByClassName("save")[0];
                button.disabled = true;
                button.innerHTML = "Changes saved";
              }
              
          })
          .catch(err => {
              console.error(err);
          });
        }
    }
}
</script>
<style scoped>
.save{
  float: right;
}
.save disabled{
  opacity: 1;
}
.label{
  display: inline-block;
  margin-right: 5px;
  min-width: 100px;
}
.info{
  color: #5c616c;
  margin-top: 5px;
}
</style>
