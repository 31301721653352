<template>
  <div>
      <div class="modal" id="modal" v-if="visible" v-on:click="close()">
      <div class="modal-content" v-on:click.stop>
        <button v-on:click="close()" class="rightbutton"> 
            <span class="buttonicon">
              <Close/>
            </span>
        </button>
         <div class="label">{{desc}}</div>
<!-- 
         <div class="list">
          <div v-for="(station, index) in stations" v-bind:key="station.orderID" class="item"  v-on:click="add(index)">
          {{station.name}}</div>
         </div> -->

        <ag-grid-vue
        style="width: 1024px; margin-top: 10px; height: 500px"
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="orders"
        :pagination="true"
        @rowClicked="onRowClicked"
        v-if="!loading">
        </ag-grid-vue>

      </div>
    </div>
  </div>
</template>

<script>
import Close from 'vue-material-design-icons/Close.vue'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
export default {
    name: "OrdersModal",
    components: {
      Close,
      AgGridVue
    },
    props: {
        desc: String,
        orders: [],
        visible: Boolean
    },
    data(){
      return{
      }
    },
    methods: {
      close: function(){
        this.$emit('closed')
      },
      onRowClicked(params) {
        this.$emit('orderSelected', params.node.data)
      }
    },
    beforeMount() {
      const tagRender = (params) => `<div class="tag ${params.value}">${params.value}</div></td>`;
      const dateRender = (params) => `${new Date(params.value).toLocaleString("nl-NL")}`;
      const dateOnlyRender = (params) => `${new Date(params.value).toLocaleDateString("nl-NL")}`;

      const orderStatus = 
      {
        "CREATED" : 'Created',
        "SLOT_ASSIGNED" : 'Slot Assigned',
        "SLOT_MISSED" : 'Slot Missed',
        "IN_PROGRESS" : 'In Progress',
        "COMPLETED" : 'Completed',
        "CANCELED" : 'Canceled',
        "ONHOLD" : 'On Hold',
        "PRINTING" : 'Printing in progress',
        "PRINTING_COMPLETE" : 'Printing complete',
      }

      const statusRender = (params) => `<div class="tag ${params.value}">${orderStatus[params.value]}</div></td>`;

      function dateComparator(date1, date2) {
        const date1Number = new Date(date1).getTime();
        const date2Number = new Date(date2).getTime();
        if (date1Number === null && date2Number === null) {
          return 0;
        }
        if (date1Number === null) {
          return -1;
        }
        if (date2Number === null) {
          return 1;
        }
        return date1Number - date2Number;
      }

      this.columnDefs = [
        { field: "orderID", sortable: true, filter: true, maxWidth: 150, headerName: "OrderID", cellRenderer: tagRender },
        { field: "status", sortable: true, filter: true, maxWidth: 150, headerName: "Status", cellRenderer: statusRender },
        { field: "assignedSlot.name", sortable: true, filter: true, maxWidth: 150, headerName: "Assigned slot", cellRenderer: tagRender },
        { field: "assignedDate", sortable: true, filter: true, headerName: "Planned for", comparator: dateComparator, cellRenderer: dateOnlyRender },
        { field: "flow.name", sortable: true, filter: true, headerName: "Flow name" },
        { field: "date", sortable: true, filter: true, headerName: "Created on", sort: "desc", comparator: dateComparator, cellRenderer: dateRender },
      ];
    },
}
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); 
}
.modal-content {
  background-color: #7994a8;
  margin: 15% auto;
  border-radius: 2px;
  height: 500px;
  width: 1024px;
  padding-top: 10px;
}
.label{
    color: white;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
}
.item{
  display: block;
  height: 40px;
  line-height: 40px;
  background-color: #475663;
  color: white;
  border-top: 1px solid #333e47;
  cursor: pointer;
  padding-left: 10px;
}
.list{
  margin-top: 10px;
}
.rightbutton{
  float: right;
  margin-right: 5px;
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  position: relative;
}

</style>