<template>
  <div>
    <OrdersModal desc='Select order to add' v-bind:orders="this.orders" v-bind:visible="this.OrdersModalVisible" 
    @orderSelected="assignSlot"
    @closed="OrdersModalVisible = false" />

    <div v-if="!loading" class="content">
      <date-range-picker 
          v-model="pickerDates"
          singleDatePicker="single"
          :ranges=false
          @update="dateChanged">
            <template
              v-slot:input="picker" 
              style="min-width: 350px;">{{ new Date(pickerDates.startDate).toLocaleDateString("nl-NL")}}
            </template>
      </date-range-picker>

    <div class="step" v-for="slot in slots" :key="slot.slotID" :style="{backgroundColor: slot.color }"> 
     
     <div class="steptitle">
       <div class="steptitleBox">{{ slot.name }} 
        <b v-bind:class = "(slot.orders.length > slot.maxOrders)?'slotFull':''" style="float: right">
        {{ slot.orders.length }}/{{ slot.maxOrders }}
        </b>
      </div>

       <div class="slotTime">{{ slot.start }} - {{ new Date(new Date("1970/01/01 " + slot.start).getTime() + slot.length * 60000).toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit', hour12: false }) }}</div>

       <button v-on:click="openOrderDialog(slot)" class="rightbutton"> 
           <span class="buttonicon">
            <PlaylistPlus/> Assign order to slot
           </span>
       </button>
     </div>

     <div v-if="slot.orders.length == 0" class="noactions">This slot is empty <br>  
     </div>

     <div class="substep" v-for="order in slot.orders" :key="order.orderID"> 
         <div class="actionnr">{{ order.orderID }}</div> 
         <div class="actionnr" :class="order.status">{{ orderStatus[order.status] }}</div> 
         </div>
    </div>

    <div class="lds-dual-ring" v-if="loading && !error"></div>
    <div v-if="error" class="error"><AlertCircleOutline/> Could not retrieve planning information</div>
    <div v-if="this.slots.length == 0" class="error"><AlertCircleOutline/> No slots for selected date</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import PlaylistPlus from 'vue-material-design-icons/PlaylistPlus.vue'
import DateRangePicker from 'vue2-daterange-picker'
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import OrdersModal from "@/components/OrdersModal";

export default {
  name: 'Planning',
  components: {
    // Table,
    AlertCircleOutline,
    PlaylistPlus,
    DateRangePicker,
    OrdersModal
  },
  data() {
    const startDate = new Date();

    return{
      pickerDates: {
        startDate
      },
      loading: true,
      error: false,
      slots: [],
      orders: [],
      OrdersModalVisible: false,
      CurrentSlot: []
    }
  },
  mounted() {
      this.dateChanged();
      this.axios
      .get('/orders')
      .then(response => {
          this.orders = response.data;
          for (var i = 0; i < this.orders.length; i++) {
            this.orders[i].url = 'order/'+this.orders[i].orderID;
          }
          this.loading = false
      })
      .catch(err => {
          this.error = true;
      });
  },
  methods:{
    dateChanged(loading=true) {
      this.loading = loading;
      this.axios.post('/slots/assigned', {date: new Intl.DateTimeFormat("nl-NL").format(this.pickerDates.startDate)})
      .then(response => {
         this.slots = response.data;
         this.loading = false;
         if(this.slots.error != null)
         {
          this.error = true;
         }
         else
         {
          this.error = false;
         }
      })
      .catch(err => {
          this.error = true;
      });
    },
    assignSlot(order){
      console.log(order);
      console.log(this.CurrentSlot);
      this.OrdersModalVisible = false;

      this.axios.post('/slot/assign/'+order.orderID, {slotID: this.CurrentSlot.slotID, 
        date: new Intl.DateTimeFormat("nl-NL").format(this.pickerDates.startDate)
      })
      .then(response => {
         if(response.error != null)
         {
          this.error = true;
         }
         else
         {
          this.error = false;
          this.dateChanged();
          this.axios
          .get('/orders')
          .then(response => {
              this.orders = response.data;
              for (var i = 0; i < this.orders.length; i++) {
                this.orders[i].url = 'order/'+this.orders[i].orderID;
              }
              this.loading = false
          })
          .catch(err => {
              this.error = true;
          });
         }
      })
      .catch(err => {
          this.error = true;
      });
      
    },
    openOrderDialog(slot){
      this.OrdersModalVisible = true;
      this.CurrentSlot = slot
    }
   },
  beforeMount() {
    this.orderStatus = 
    {
      "CREATED" : 'Created',
      "SLOT_ASSIGNED" : 'Slot Assigned',
      "SLOT_MISSED" : 'Slot Missed',
      "IN_PROGRESS" : 'In Progress',
      "COMPLETED" : 'Completed',
      "CANCELED" : 'Canceled',
      "ONHOLD" : 'On Hold',
      "PRINTING" : 'Printing in progress',
      "PRINTING_COMPLETE" : 'Printing complete',
      }

	},
}
</script>
<style scoped>
.tag{
  display: inline-block;
  margin-top: 5px;
}

.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 25px;
}

.FAIL{
  background-color: #bd7171;
  min-width: 50px;
}

.slotFull{
  background-color: #bd7171;
  text-align: center;
  border-radius: 3px;
}

.PASS {
  background-color: #67995b;
  min-width: 50px;
}

.desc{
  margin-top: 5px;
}
.params{
  display: inline-block;
}

.noactions{
  margin-left: 2px;
}

.steptitleBox{
  display: inline-block;
  color: white;
  font-weight: normal;
  width: 100%;
}

.steptitleBox:hover{
  border-color: #cfd6e6;
}

.argumentValue{
  display: inline-block;
  background-color:  #78a583;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 0px;
  height: 10px;
  -webkit-transition-duration: .0s;
  transition-duration: .0s;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.argumentDesc{
  display: inline-block;
  background-color:  #475663;
  padding-left: 5px;
  border-radius: 3px;
  margin-left: 5px;
}

.actionnr{
  display: inline-block;
  padding: 3px;
  border-radius: 3px;
  margin-right: 3px;
}
.rightbutton{
  float: right;
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  position: relative;
  margin-bottom: 5px;
}

.rightbutton:hover {
    background-color: #9c9c9c;
    cursor: pointer;
    -webkit-transition: background-color 70ms linear;
    -moz-transition: background-color 70ms linear;
    -o-transition: background-color 70ms linear;
    transition: background-color 70ms linear;
}

.actionbutton{
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  margin-top: 5px;
}

.actionbutton:hover {
    background-color: #9c9c9c;
    cursor: pointer;
    -webkit-transition: background-color 70ms linear;
    -moz-transition: background-color 70ms linear;
    -o-transition: background-color 70ms linear;
    transition: background-color 70ms linear;
}

.buttonicon{
    pointer-events: none;
}
.step{
  display: inline-block;
  background-color: #464646;
  color: white;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 2px;
}
.steptitle{
    margin-bottom: 5px;
    margin-left: 2px;
    font-weight: bold;
}

.slotTime{
    font-weight: bold;
    width: fit-content;
}
.substep{
    display: inline-block;
    border-color: #f5f5f5;
    border-style: solid;
    border-width: 1px;
    color: white;
    box-sizing: border-box;
    padding: 2px;
    margin-right: 2px;
    margin-top: 2px;
    border-radius: 4px;
}

.COMPLETED{
  background-color: #71bd84; 
}

.INPROGRESS{
  background-color: #7971bd;
}

.SLOT_ASSIGNED{
  background-color: #df8764;
  display: none;
}

.SLOT_MISSED{
  background-color: #bd7171;

}
</style>
