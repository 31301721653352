<template>
    <div class="topBarItem" :class="{ 'right' : right}">
        <Account v-if="right"/> 
        <div class="title">{{title}}</div>
        <chevron-down-icon v-if="icon" class="icon"/>
        <div class="dropdown" v-if="icon">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
    import Account from 'vue-material-design-icons/Account.vue'
    export default {
        name: "NavigationItem",
        components: {
          ChevronDownIcon,
            Account
        },
        props: {
            title: String,
            right: Boolean,
            icon: Boolean
        }
    }
</script>

<style scoped>
    .topBarItem{
        height: 100%;
        line-height: 57px;
        display:inline-block;
        vertical-align:top;
        text-align: center;
        margin-right: 15px;
        color: #ebebeb;
        cursor: pointer;
        min-width: 80px;
    }
    .topBarItem:hover{
        color: #c0c0c0;
        transition:.2s;
    }
    .title{
        display: inline-block;
    }
    .icon{
        margin-left: 5px;
    }
    .right{
        float: right;
    }
    .dropdown{
        width: 200px;
        background-color: white;
        text-align: left;
        border-radius: 3px;
        position: absolute;
        margin-top: -2px;
        border: 1px solid #dadada;
        border-top: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        display: none;
    }
    .dropdown div{
        padding-left: 15px;
        border-top: 1px solid #dadada;
        color: #292929;
    }
    .dropdown div:hover{
        background-color: #ebebeb;
    }
    .topBarItem:hover .dropdown{
        display: block;
    }
</style>