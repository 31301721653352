<template>
  <div class="banner">
            <div class="bannercontent">
                <div class="logo"></div>
                <div class="bannertitle">Login</div>
                <div class="bannerdesc">Please login using your username and password
                </div>
                <input v-on:keyup.enter="login" type="text" name="username" placeholder="Username"> <br>
                <input v-on:keyup.enter="login" type="password" name="password" placeholder="Password">
                <button v-on:click="login" class="button">Login</button>

                <div style="opacity: 0;" class="message message-error" id="login_error"><AlertCircleOutline/> {{error}}</div>
            </div>
        </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'

export default {
  name: 'Home',
  components: {
    AlertCircleOutline
  },
  data() {
     return{
       error: ''
     }
  },
  methods: {
    setCookie: function (name,value,days) {
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/;SameSite=Lax";
    },

    login: function (event) {
      var username = document.querySelector("input[name=username]").value;
      var password = document.querySelector("input[name=password]").value;
      this.axios.post('/user/login', {username: username, password: password})
      .then(response => {
          if(response.data.error != null){
            this.error = response.data.error;
            document.getElementById("login_error").style.opacity = '1';
          }
          else{
            if(response.data.sessionID != null){
              this.setCookie('Session-Id', response.data.sessionID, 1);
              this.$router.push({ path: window.location.pathname });
              this.axios.defaults.headers.common['Session-Id'] = response.data.sessionID;

              this.axios.get('/user/session').then(response => {
                if(response.data.userID != null){
                  this.$root.session = response.data
                }
              });
            }
          }
          
      })
      .catch(err => {
          console.error(err);
          //this.loading = false;
          //this.errored = true;
      });
    }
  }
}
</script>

<style scoped>
.banner{
  height: 250px;
  transition: height .15s linear;
}
.bannercontent{
  margin-top: 40px;
}
.button{
  margin-left: 5px;
}
</style>
