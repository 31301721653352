<template>
    <div id="app">
        <Navigation/>
        <PageTitle/>
        <InfoDialog :desc="infoDialogMessage" :visible="infoDialogVisible" 
        @closed="infoDialogClosed" />
        <router-view/>
    </div>
</template>

<script>

import Navigation from "@/components/Navigation";
import PageTitle from "@/components/PageTitle";
import InfoDialog from "@/components/InfoDialog";

export default {
  name: 'app',
  components: {
      Navigation,
      PageTitle,
      InfoDialog
  },
  data() {
    return{
        infoDialogVisible: false,
        infoDialogMessage: ""
    }
  },
  beforeCreate () {
      this.axios.get('/user/session').then(response => {
          if(response.data.userID != null){
            this.$root.session = response.data
          }
          else{
            var current = window.location.href;
            this.$router.push({ name: 'Login' });
            window.history.replaceState({ additionalInformation: 'Login redirect' }, 'Login', current);
          }
      })
      .catch(err => {
          console.error(err);
      });
      this.axios.interceptors.response.use(
        response => {
            if (response.status === 200) {
                if(response.data.error != null && this.$route.name != "Login"){
                    this.infoDialogMessage = response.data.error;
                    //this.infoDialogVisible = true;
                }
                return Promise.resolve(response);
            } 
            else {
                return Promise.reject(response);
            }
        },error => {
            if (error.response.status) {
            switch (error.response.status) {
                case 500:
                    this.infoDialogMessage = 'Something went wrong: HTTP 500';
                    this.infoDialogVisible = true;
                    break;
            }
            return Promise.reject(error.response);
            }
        }
        );
    },
    methods: {
        infoDialogClosed(){
            this.infoDialogVisible = false;
        }
    }
}
</script>

<style>
    .button {
        color: #ffff;
        background-color: #ef6a39;
        min-width: 80px;
        min-height: 30px;
        border: none;
        border-radius: 2px;
        -webkit-transition: background-color 70ms linear;
        -moz-transition: background-color 70ms linear;
        -o-transition: background-color 70ms linear;
        transition: background-color 70ms linear;
        position: relative;
    }
    .button:disabled{
        opacity: .5;
        cursor: auto;
        pointer-events: none;
    }

    .tag {
        color: #ffff;
        background-color: #7189bd;
        margin-bottom: 5px;
        width: fit-content;
        padding: 5px;
        border-radius: 2px;
    }

    .tag-red{
        background-color: #bd7171;
    }

    .tag-green{
        background-color: #80bd71;
    }

    .button:hover {
        background-color: #df5d2f;
        cursor: pointer;
        -webkit-transition: background-color 70ms linear;
        -moz-transition: background-color 70ms linear;
        -o-transition: background-color 70ms linear;
        transition: background-color 70ms linear;
    }
    .bannertitle{
        color: #575757;
        font-weight: bold;
        font-size: 32px;
        padding-top: 10px;
    }
    .bannerdesc{
        color: #777777;
        padding-top: 5px;
        padding-bottom:10px;
    }
    .contenttitle{
        color: #575757;
        font-weight: bold;
        font-size: 16px;
        padding-top: 10px;
    }
    .message{
        color: #f3f3f3;
        margin-top: 12px;
        margin-bottom: 12px;
        padding: 5px;
        background-color: #a0a0a0;
        width: fit-content;
        border-radius: 2px;
        transition: opacity .15s linear;
    }
    .message-error{
        background-color: #bd7171;
    }
    #app{
        height: 100%;
    }
    a{
        color: #ef6a39;
        text-decoration: none;
    }
    body {
      background-color: #eeeeee;
      margin: 0 auto;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      position: relative;
       
    }
    .banner{
        background-image: url(~@/assets/mock.jpg);
        background-position: center;
        background-color: #d9d9d9;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 160px;
        display: flex;
        justify-content: center;
    }
    .bannercontent{
        max-width: 1524px;
        width: 100%;
        align-self: center;
        text-align: left;

    }
    .content{
        width: 1524px;
        display: inline-block;
        padding-top: 5px;
        text-align: left;
    }
    .lds-dual-ring{
        margin-top: 25px;
    }
    .lds-dual-ring:after {
        content: " ";
        display: inline-block;
        width: 32px;
        height: 32px;
        margin: 4px;
        border-radius: 50%;
        border: 6px solid #ef6a39;
        border-color: #ef6a39 transparent #ef6a39 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
        }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
}
input {
        padding: 7px;
        transition-duration: .3s;
        border-radius: 3px;
        color: #5c616c;
        background-color: #fff;
        border: 1px solid #cfd6e6;
        margin-top: 5px;
    }
select{
    padding: 7px;
    transition-duration: .3s;
    border-radius: 3px;
    color: #5c616c;
    background-color: #fff;
    border: 1px solid #cfd6e6;
    box-shadow: inset 0 2px 4px hsla(0,0%,100%,.05);
    margin-top: 5px;
}
.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 20px;
  text-align: center;
}
</style>

