<template>
  <div>
    
    <!-- Filter options checkbox: Station, Fail, Pass
    Order by
    Search
    Limit items per page -->
    <div class="lds-dual-ring" v-if="loading && !error"></div>
    <div v-if="error" class="error"><AlertCircleOutline/> Orders not found</div>
    
    <div v-if="!loading" class="content">
      <div class="bannertitle" >Order overview</div>

     <!-- <Table :columns="['Order', 'Flow', 'Date']" :items="orders" v-slot="{row}">
        <td>{{ row.orderID }}</td>
        <td>{{ row.flow.name}}</td>
        <td>{{ row.date }}</td>
     </Table> -->

     <ag-grid-vue
				style="width: 1524px; margin-top: 10px"
				class="ag-theme-alpine"
				domLayout="autoHeight"
				:columnDefs="columnDefs"
				:rowData="orders"
				:pagination="true"
				@rowClicked="onRowClicked"
				@rowDoubleClicked="onRowDoubleClicked"
				v-if="!loading"
			>
			</ag-grid-vue>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import Table from "@/components/Table"

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
  name: 'Orders',
  components: {
    // Table,
    AlertCircleOutline,
    AgGridVue
  },
  methods: {
		onRowClicked(params) {
			window.onmousemove = function(e) {
				if (!e) e = window.event;
				if (e.altKey) {
					navigator.clipboard.writeText(
						params.node.data.TestResult.leftSN + "	" + params.node.data.TestResult.rightSN + "	" + params.node.data.TestResult.cradleSN
					);
				}
			};
		},
		onRowDoubleClicked(params) {
			let route = this.$router.resolve({ path: params.node.data.url });
			window.open(route.href);
		},
	},
  data() {
    return{
      stations: [],
      loading: true,
      error: false,
      orders: []
    }
  },
  mounted() {
      this.axios
      .get('/orders')
      .then(response => {
          this.orders = response.data;
          for (var i = 0; i < this.orders.length; i++) {
            this.orders[i].url = 'order/'+this.orders[i].orderID;
          }
          this.loading = false
      })
      .catch(err => {
          this.error = true;
      });
  },
  beforeMount() {
		const tagRender = (params) => `<div class="slotTag tag ${params.value}">${params.value}</div></td>`;
		const dateRender = (params) => `${new Date(params.value).toLocaleString("nl-NL")}`;

    const orderStatus = 
    {
      "CREATED" : 'Created',
      "SLOT_ASSIGNED" : 'Slot Assigned',
      "SLOT_MISSED" : 'Slot Missed',
      "IN_PROGRESS" : 'In Progress',
      "COMPLETED" : 'Completed',
      "OBA_REQUIRED" : 'OBA Required',
      "CANCELED" : 'Canceled',
      "ONHOLD" : 'On Hold',
      "PRINTING" : 'Printing in progress',
      "PRINTING_COMPLETE" : 'Printing complete',
      }

    const statusRender = (params) => `<div class="tag ${params.value}">${orderStatus[params.value]}</div></td>`;

    function slotRender(params)
    {
      if(params.value == null)
      {
        params.value = "No slot selected"
      }
      return `<div class="">${params.value}</div></td>`;
    }
    
    function dateOnlyRender(params)
    {
      if(params.value == null)
      {
        return `No date selected`
      }
      else
      {
        return `${new Date(params.value).toLocaleDateString("nl-NL")}`;
      }
    }

		function dateComparator(date1, date2) {
			const date1Number = new Date(date1).getTime();
			const date2Number = new Date(date2).getTime();
			if (date1Number === null && date2Number === null) {
				return 0;
			}
			if (date1Number === null) {
				return -1;
			}
			if (date2Number === null) {
				return 1;
			}
			return date1Number - date2Number;
		}

		this.columnDefs = [
			{ field: "orderID", sortable: true, filter: true, maxWidth: 150, headerName: "OrderID", cellRenderer: tagRender },
      { field: "status", sortable: true, filter: true, maxWidth: 150, headerName: "Status", cellRenderer: statusRender },
      { field: "customerReference", sortable: true, filter: true, maxWidth: 150, headerName: "Cust. Ref" },
      { field: "assignedSlot.name", sortable: true, filter: true, maxWidth: 150, headerName: "Assigned slot", cellRenderer: slotRender },
      { field: "assignedDate", sortable: true, filter: true, maxWidth: 150, headerName: "Planned for", comparator: dateComparator, cellRenderer: dateOnlyRender },
			{ field: "flow.name", sortable: true, filter: true, maxWidth: 120, headerName: "Flow name" },
			{ field: "date", sortable: true, filter: true, maxWidth: 150, headerName: "Created on", sort: "desc", comparator: dateComparator, cellRenderer: dateRender },
      { field: "leftSN", sortable: true, filter: true, maxWidth: 150, headerName: "Left serial" },
      { field: "rightSN", sortable: true, filter: true, maxWidth: 150, headerName: "Right serial" },
      { field: "cradleSN", sortable: true, filter: true, maxWidth: 150, headerName: "Cradle serial" },
		]; 
	},
}
</script>
<style>
.tag{
  display: inline-block;
  margin-top: 5px;
  min-width: 80px;
}

.slotTag{
  background-color: transparent;
  border-color: #5e5e5e;
  border-width: 1px;
  border-style: solid;
  color: black;
}

.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 25px;
}

.FAIL{
  background-color: #bd7171;
  min-width: 50px;
}

.PASS {
  background-color: #67995b;
  min-width: 50px;
}

.COMPLETED{
  background-color: #71bd84;
}

.INPROGRESS{
  background-color: #7971bd;
}

.SLOT_ASSIGNED{
  background-color: #df8764;
}

.SLOT_MISSED{
  background-color: #bd7171;
}

.OBA_REQUIRED{
  background-color: #d1ce1d;
}

</style>
