<template>
  <div>
      <div class="modal" id="modal" v-if="visible" v-on:click="close()">
      <div class="modal-content" v-on:click.stop>
        <button v-on:click="close()" class="rightbutton"> 
            <span class="buttonicon">
              <Close/>
            </span>
        </button>
        <div class="label"><AlertCircleOutline/> Information</div>
        <div class="content">
          {{desc}}<br><br>
        <button class="button" v-on:click="close()">Close</button>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import Close from 'vue-material-design-icons/Close.vue'
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
    export default {
        name: "InfoDialog",
        components: {
          Close,
          AlertCircleOutline
        },
        props: {
            desc: String,
            visible: Boolean
        },
        data(){
          return{
          }
        },
         methods: {
            close: function(){
              this.$emit('closed')
            }
         }
  }
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); 
}
.modal-content {
  background-color: #7994a8;
  margin: 15% auto;
  border-radius: 3px;
  width: 500px;
  padding-top: 10px;
}
.content{
  background-color: white;
  width: 460px;
  padding: 20px;
  margin-top: 10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.label{
    color: white;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
}

.cancel{
  background-color: #7994a8;
  margin-right: 5px;
}
.button{
  float: right;
  margin-right: 5px;
}

.rightbutton{
  float: right;
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  position: relative;
  margin-right: 8px;
}
</style>