import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Test from '../views/Test.vue'
import Result from '../views/Result.vue'
import Results from '../views/Results.vue'
import Tests from '../views/Tests.vue'
import Stations from '../views/Stations.vue'
import Station from '../views/Station.vue'
import Orders from '../views/Orders.vue'
import Order from '../views/Order.vue'
import Flows from '../views/Flows.vue'
import Flow from '../views/Flow.vue'
import Planning from '../views/Planning.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    auth: true
  },
  {
    path: '/test/:id/:version',
    name: 'Test',
    component: Test
  },
  {
    path: '/result/:id',
    name: 'Test result',
    component: Result
  },
  {
    path: '/results/',
    name: 'Test results',
    component: Results
  },
  {
    path: '/tests/',
    name: 'Manage tests',
    component: Tests
  },
  {
    path: '/stations/',
    name: 'Manage stations',
    component: Stations
  },
  {
    path: '/station/:id',
    name: 'Edit station',
    component: Station
  },
  {
    path: '/orders/',
    name: 'Order overview',
    component: Orders
  },
  {
    path: '/planning/',
    name: 'Order planning',
    component: Planning
  },
  {
    path: '/order/:id',
    name: 'Order details',
    component: Order
  },
  {
    path: '/flow/:id',
    name: 'Edit flow',
    component: Flow
  },
  {
    path: '/flows/',
    name: 'Manage flows',
    component: Flows
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
