<template>
	<div>
		<!-- Filter options checkbox: Station, Fail, Pass
    Order by
    Search
    Limit items per page -->
		<div class="lds-dual-ring" v-if="loading && !error"></div>
		<div v-if="error" class="error"><AlertCircleOutline /> Test results not found</div>

		<!-- <div class="content"> -->
		<div class="bannertitle" v-if="!loading">Test results</div>

		<center>
			<ag-grid-vue
				style="width: 1524px; margin-top: 10px"
				class="ag-theme-alpine"
				domLayout="autoHeight"
				:columnDefs="columnDefs"
				:rowData="testresults"
				:pagination="true"
				@rowClicked="onRowClicked"
				@rowDoubleClicked="onRowDoubleClicked"
				v-if="!loading"
			>
			</ag-grid-vue>
		</center>
	</div>
	<!-- </div> -->
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from "vue-material-design-icons/AlertCircleOutline.vue";
import Table from "@/components/Table";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
	name: "Results",
	components: {
		// Table,
		AlertCircleOutline,
		AgGridVue,
	},
	methods: {
		onRowClicked(params) {
			window.onmousemove = function(e) {
				if (!e) e = window.event;
				if (e.altKey) {
					navigator.clipboard.writeText(
						params.node.data.TestResult.leftSN + "	" + params.node.data.TestResult.rightSN + "	" + params.node.data.TestResult.cradleSN
					);
				}
			};
		},
		onRowDoubleClicked(params) {
			let route = this.$router.resolve({ path: params.node.data.url });
			window.open(route.href);
		},
	},
	data() {
		return {
			testresults: [],
			loading: true,
			error: false,
		};
	},
	mounted() {
		var orderid = this.$route.query.orderid;
		if (typeof orderid === "undefined")
		{
			orderid = '';
		}
		var stationid = this.$route.query.stationid;
		if (typeof stationid === "undefined")
		{
			stationid = '';
		}
		else
		{
			stationid = '/'+stationid
		}
		this.axios
			.get("/testresults/"+orderid+stationid)
			.then((response) => {
				this.testresults = response.data;
				for (var i = 0; i < this.testresults.length; i++) {
					this.testresults[i].url = "result/" + this.testresults[i].TestResult.testResultID;
				}
				this.loading = false;
			})
			.catch((err) => {
				this.error = true;
			});
	},
	beforeMount() {
		const tagRender = (params) => `<div class="tag ${params.value}">${params.value}</div></td>`;
		const dateRender = (params) => `${new Date(params.value).toLocaleString("nl-NL")}`;

		function dateComparator(date1, date2) {
			const date1Number = new Date(date1).getTime();
			const date2Number = new Date(date2).getTime();
			if (date1Number === null && date2Number === null) {
				return 0;
			}
			if (date1Number === null) {
				return -1;
			}
			if (date2Number === null) {
				return 1;
			}
			return date1Number - date2Number;
		}

		this.columnDefs = [
			{ field: "TestResult.result", sortable: true, filter: true, maxWidth: 100, headerName: "Result", cellRenderer: tagRender },
			{ field: "TestResult.orderID", sortable: true, filter: true, maxWidth: 150, headerName: "OrderID" },
			{ field: "testName", sortable: true, filter: true },
			{ field: "stationName", sortable: true, filter: true },
			{ field: "TestResult.date", sortable: true, filter: true, maxWidth: 180, sort: "desc", comparator: dateComparator, cellRenderer: dateRender },
		];
	},
};
</script>
<style>
.tag {
	display: inline-block;
	margin-top: 5px;
	margin-right: 2px;
	line-height: 15px;
	text-align: center;
}

.error {
	width: inherit;
	height: 500px;
	line-height: 500px;
	color: #5e5e5e;
	font-size: 25px;
}

.FAIL {
	background-color: #bd7171;
	min-width: 50px;
}

.PASS {
	background-color: #67995b;
	min-width: 50px;
}
</style>
