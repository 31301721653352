<template>
  <div>
    <div class="lds-dual-ring" v-if="loading"></div>
    <div v-if="error" class="error" id="login_error"><AlertCircleOutline/> Could not retrieve test results</div>
    <div class="banner" v-if="!loading && !error">
        <div class="bannercontent">
          <div class="left">
            <div class="logo"></div>
            <div class="bannertitle">Test results</div>
            <div class="bannerdesc">{{testresult.date}}1</div>
            <div class="tag" :class="testresult.result">{{testresult.result}}</div><br>
            <div class="tag">{{testresult.testName}} V{{testresult.testVersion}}</div>
          </div>

          <div class="right">
            <button class="button order" @click="$router.push('/order/'+testresult.orderID)"><OpenInNew/> {{ testresult.orderID }}</button>
          </div>
        </div>
      </div>
    
    <div class="content">
      <TestStepItem v-for="item in testresult.steps" :key="item.testResultID" v-bind:step="item" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import TestStepItem from "@/components/TestStepItem";
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue'
export default {
  name: 'Result',
  components: {
   TestStepItem,
    AlertCircleOutline,
    OpenInNew
  },
  data() {
    return{
      testresult: [],
      parts: [],
      loading: true,
      error: false
    }
  },
  mounted() {
    this.dateChanged();
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods:{
    dateChanged(loading=true) {
      this.loading = loading;
      this.axios
      .get('/testresult/'+this.$route.params.id)
      .then(response => {
          this.testresult = response.data;
          this.loading = false
          if (this.testresult.result == 'RUNNING')
          {
            this.timer = setInterval(() => {
            this.dateChanged(false);
            clearInterval(this.timer)
          }, 5000)
          }
      })
      .catch(err => {
          console.error(err);
          this.loading = false;
          this.error = true;
      });

      this.axios
      .get('/testresult/'+this.$route.params.id+'/parts')
      .then(response => {
          this.parts = response.data;
      })
      .catch(err => {
          console.error(err);
      });
    }
   }
}
</script>
<style scoped>
.left{
  float: left;
  padding-top: 10px;
}
.right{
  float: right;
  padding-top: 25px;
}

.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 25px;
}
.cradle{
  background-image: url('/assets/bamboo_cradle_black.png');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  height: 150px;
  width: 200px;
  display: inline-block;
}
.cradle div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding: 2px;
}
.earbud{
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 150px;
  display: inline-block;
  margin-right: 10px;
}

.earbud div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.LEFT{
  background-image: url('/assets/bamboo_earbud_left.png');
  background-position-x: -20px;
}
.RIGHT{
  background-image: url('/assets/bamboo_earbud_right.png');
  background-position-x: -95px;
}

.FAIL{
  background-color: #bd7171;
}

.PASS {
  background-color: #67995b;
}

.WARNING {
  background-color: #c78e3a;
}

.BLUE{
  background-image: url('/assets/bamboo_cradle_black.png');
}

.RED{
  background-image: url('/assets/bamboo_cradle_red.png');
}

.BLACK{
  background-image: url('/assets/bamboo_cradle_black.png');
}

.CUSTOM{
  background-image: url('/assets/bamboo_cradle_custom.png');
}
</style>
