<template>
  <div>
    
    <!-- Filter options checkbox: Station, Fail, Pass
    Order by
    Search
    Limit items per page -->
    <div class="lds-dual-ring" v-if="loading"></div>
    <div v-if="error" class="error" id="login_error"><AlertCircleOutline/> Stations not found</div>
    
    <div class="content">
      <div class="bannertitle" v-if="!loading">Manage stations</div>

     <Table :columns="['Name', 'Status']" :items="stations" v-if="!loading" v-slot="{row}">
        <td>{{ row.name }}</td>
        <td><div class="tag">{{ row.status }}</div></td>
     </Table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import Table from "@/components/Table"

export default {
  name: 'Stations',
  components: {
    Table,
    AlertCircleOutline
  },
  data() {
    return{
      stations: [],
      loading: true,
      error: false
    }
  },
  mounted() {
      this.axios
      .get('/stations')
      .then(response => {
          this.stations = response.data;
          for (var i = 0; i < this.stations.length; i++) {
            this.stations[i].url = 'station/'+this.stations[i].stationID;
          }
          this.loading = false
      })
      .catch(err => {
          console.error(err);
          this.loading = false;
          this.error = true;
      });
  }
}
</script>
<style scoped>
.tag{
  display: inline-block;
  margin-top: 5px;
}

.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 25px;
}

.FAIL{
  background-color: #bd7171;
  min-width: 50px;
}

.PASS {
  background-color: #67995b;
  min-width: 50px;
}
.delete{
  min-width: 20px;
  margin-right: 2px;
  background-color: transparent;
  color: #bd7171;
}
.delete:hover{
background-color: #bd7171;
color: white;
}
</style>
