<template>
  <div>
      <div class="modal" id="modal" v-if="visible" v-on:click="close()">
      <div class="modal-content" v-on:click.stop>
        <button v-on:click="close()" class="rightbutton"> 
            <span class="buttonicon">
              <Close/>
            </span>
        </button>
         <div class="label">{{desc}}</div>

         <div class="list">
          <div v-for="(action, index) in actions" v-bind:key="action.actionID" class="item"  v-on:click="add(index)">
          {{action.description}}</div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from 'vue-material-design-icons/Close.vue'
    export default {
        name: "Modal",
        components: {
          Close
        },
        props: {
            desc: String,
            buttontxt: String,
            type: String,
            actions: [],
            visible: Boolean
        },
        data(){
          return{
          }
        },
         methods: {
            add: function(id){
              this.$emit('addAction', this.actions[id])
            },
            close: function(){
              this.$emit('closed')
            }
         }
  }
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); 
}
.modal-content {
  background-color: #7994a8;
  margin: 15% auto;
  border-radius: 2px;
  width: 500px;
  padding-top: 10px;
}
.label{
    color: white;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
}
.item{
  display: block;
  height: 40px;
  line-height: 40px;
  background-color: #475663;
  color: white;
  border-top: 1px solid #333e47;
  cursor: pointer;
}
.list{
  margin-top: 10px;
}
.rightbutton{
  float: right;
  margin-right: 5px;
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  position: relative;
}

</style>