<template>
    <div class="topBar">
        <div class="topBarContent">
            Chisel <chevron-right-icon/> <b>{{$route.name}}</b>
        </div>
    </div>
</template>

<script>
    import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
    export default {
        components: {
            ChevronRightIcon
        },
        name: "PageTitle"
    }
</script>

<style scoped>
    .topBar{
        background-color: #475663;
        box-shadow: 0 1px 0 #313b44;
        text-align: center;
        width: 100%;
        height: 40px;
        top: 0;
        z-index: 100;
    }
    .topBarContent{
        text-align: left;
        display: inline-block;
        max-width: 1524px;
        width: 100%;
        height: 70px;
        color: white;
        line-height: 40px;
    }
</style>