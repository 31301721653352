<template>
   <div class="step" :class="step.result">
        <div class="steptitle">{{step.description}}</div>

        <div class="substep" v-for="action in step.actions" :key="action.result.stepActionResultID" 
            :class="action.result.result"> 
         <CircleOutline v-if="action.result.result == null"/>
         <Check v-if="action.result.result == 'PASS'"/>
         <Alert v-if="action.result.result == 'WARNING'"/>
         <Close v-if="action.result.result == 'FAIL'"/> {{action.action.methodName.split('.')[0]}} <chevron-right-icon/> {{action.action.methodName.split('.')[1]}} ({{action.parameters}})
         <button v-if="action.result.result" v-on:click="expand(action.result.actionNR+'-'+action.result.stepNR, $event)" class="rightbutton"> 
            <span class="buttonicon">
              <ChevronDown/>
            </span>
            <span class="buttonicon" style="display: none;">
              <ChevronUp />
            </span>
           </button>

         <div class="details" style="display: none;" :id="action.result.actionNR+'-'+action.result.stepNR">
          <div class="desc">{{action.action.description}}</div>
            <div class="infobox"> 
              {{action.result.details}}
              <!-- 01-01-2021 13:41: Requesting user to place earbuds in Holder<br>
              01-01-2021 13:42: Charge contacts +5V<br>
              01-01-2021 13:42: Listening for BD adress...<br>
              01-01-2021 13:42: Earbud L detected, BD: 6C:CE:3D:5E:0A:8F<br>
              01-01-2021 13:42: Could not detect earbud R in 10 seconds, retrying<br>
              01-01-2021 13:42: Earbud R detected, BD: 53:CA:38:BF:DA:10<br> -->
            </div>
            <div class="image">
              <img v-for="(data, index) in action.result.attachments" :key="index" :src="data"/>
            </div>
         </div>

        </div>
    </div>
</template>

<script>
    import Check from 'vue-material-design-icons/Check.vue'
    import Close from 'vue-material-design-icons/Close.vue'
    import Alert from 'vue-material-design-icons/Alert.vue'
    import CircleOutline from 'vue-material-design-icons/CircleOutline.vue'
    import ChevronDown from 'vue-material-design-icons/ChevronDown.vue'
    import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
    import ChevronUp from 'vue-material-design-icons/ChevronUp.vue'
    export default {
        name: "TestStepItem",
        components: {
          Check,
          Alert,
          ChevronRightIcon,
          Close,
          CircleOutline,
          ChevronDown,
          ChevronUp
        },
        props: {
            step: [],
        },
        methods: {
           update: function (actionID, event) {
             var details = document.getElementById(actionID);
             var down = event.target.children.item(0);
             var up = event.target.children.item(1);

             if(down.style.display == "") {
              down.style = "display: none";
              up.style = "";
              details.style.display = "block";
             }
             else{
              up.style = "display: none";
              down.style = "";
              details.style.display = "none";
             }
           },
           updateSteps: function (){
            for(let i = 0; i < this.step.actions.length; i++){
              console.log(this.step.actions[i]);
              if (this.step.actions[i].result.result == 'FAIL'){
                this.step.result = 'FAIL-STEP';
              }
              if (this.step.actions[i].result.result == 'PASS'){
                this.step.result = 'PASS-STEP';
              }
            }
           },
           expand: function (actionID, event) {
             var details = document.getElementById(actionID);
             var down = event.target.children.item(0);
             var up = event.target.children.item(1);

             if(down.style.display == "") {
              down.style = "display: none";
              up.style = "";
              details.style.display = "block";
             }
             else{
              up.style = "display: none";
              down.style = "";
              details.style.display = "none";
             }
           }
        },
        created(){
          this.updateSteps();
        },
        updated(){
          this.updateSteps();
        }
    }
</script>

<style scoped>

.image{
  width: auto;
  margin-top: 5px;
}

.image img{
  width: 100%;
}

.desc{
  margin-top: 5px;
}
.param{
  display: inline-block;
  font-weight: bold;
}
 .infobox{
  font-family: monospace;
  background-color: #494949;
  padding: 10px;
  margin-top: 5px;
  word-wrap: break-word;
  border-radius: 2px;
  white-space: pre-line;
}
.rightbutton{
  float: right;
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  position: relative;
}

.rightbutton:hover {
    background-color: #9c9c9c;
    cursor: pointer;
    -webkit-transition: background-color 70ms linear;
    -moz-transition: background-color 70ms linear;
    -o-transition: background-color 70ms linear;
    transition: background-color 70ms linear;
}

.buttonicon{
    pointer-events: none;
}
.step{
  display: inline-block;
  background-color: #a3a3a3;
  color: white;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 2px;
}
.steptitle{
    margin-bottom: 5px;
    margin-left: 2px;
    font-weight: bold;
}
.substep{
    display: inline-block;
    background-color: #414040;
    color: white;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    margin-bottom: 2px;
    opacity: 0.5;
}
.FAIL{
  background-color: #bd7171;
  opacity: 1;
}

.FAIL-STEP{
  background-color: #995b5b;
}

.PASS-STEP{
  background-color: #80bd71;
}

.WARNING {
  background-color: #df5d2f;
  opacity: 1;
}

.PASS {
  background-color: #67995b;
  opacity: 1;
}
</style>