<template>
  <div>
    <div class="lds-dual-ring" v-if="loading"></div>
    <div v-if="error" class="error" id="login_error"><AlertCircleOutline/> Could not retrieve flow</div>
    <div class="banner" v-if="!loading && !error">
        <div class="bannercontent">
          <div class="left">
            <div class="logo"></div>
            <div class="bannertitle">{{flow.name}}</div>
            <div class="bannerdesc">Flow definition</div>
          </div>
        </div>
      </div>
    
    <div class="content">
      <ModelModal desc='Edit model' v-bind:visible="this.modelModalVisible" 
      @delete="modelDeleted" 
      @closed="modelClosed"
      @changed="enableSaving"
      v-bind:model="this.flow.allowedModels[this.currentModelIndex]" />

      <Dialog v-bind:desc="this.confirmDeleteDesc"
      v-bind:visible="this.confirmDeleteVisible" 
      @closed="confirmDeleteClosed"
      @yes="confirmDelete"/>

      <StationsModal desc='Select station' v-bind:stations="this.stations" v-bind:visible="this.addStationVisible" 
      @stationAdded="stationAdded" 
      @closed="stationListClosed" />

      <button class="button save" @click="save" disabled>Changes saved</button>

      <div class="contenttitle">Models</div>
      <div class="bannerdesc">The model(s) this flow should apply to. Select a model to add optional variables that can be used as arguments in test definitions. <br>
        
      </div>
     
      <button v-for="(node, index) in flow.allowedModels" :key="node.key" class="button buttonModel" @click="editModel(index)">
        <SquareEditOutline/> {{ node.model }}
      </button>
      <button class="button" @click="addModel">+ Add</button>

      <div class="bannerdesc">
        If multiple models can be matched during testing (for example with and without a color code or earbud and cradle) variables with the same name are overwritten. <br>
        Most left = lowest priority, most right = highest priority. 
      </div>

      <div class="contenttitle">Nodes</div>
      <div class="bannerdesc">This section contains the mandatory stations and station flow. Orders can only proceed to the next station when the previous stations test has been passed succesfully. The engineering station is always the first node in the flow.
        Each station should be assigned a test definition and test version
      </div>
      <div class="right"><button class="button buttonRight" @click="addStation()">+ Add station</button></div>
      <draggable v-model="flow.nodes" @start="drag=true" @end="drag=false" @change="enableSaving">
        <div v-for="(node,index) in flow.nodes" :key="node.key" style="display: inline-block;" draggable="true">
          <div class="node active">
            <b style="padding: 8px">{{ node.name }}</b>
            <div v-if="node.hasOwnProperty('testID')" class="nodeItem nodeItemActive">
              <select @change="onChangeTest(index, $event)">
                <option v-for="test in tests" :key="test.testID+test.version" :value="test.testID" :version="test.version" :selected="test.testID == node.testID && test.version == node.testVersion">{{test.name}} - V{{test.version}}</option>
              </select>
            </div>
            <div v-if="!node.hasOwnProperty('testID')" class="nodeItem nodeItemActive">Engineering</div>
            <button :disabled="!node.hasOwnProperty('testID')" class="button buttonDelete" @click="removeStation(index)"><Delete/> Remove</button>
          </div>
          
          <div v-if="index != (flow.nodes.length-1)" class="arrow"><ArrowRight/></div>
      </div>
    </draggable>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import SquareEditOutline from 'vue-material-design-icons/SquareEditOutline.vue'
import ModelModal from "@/components/ModelModal";
import StationsModal from "@/components/StationsModal";
import Dialog from '../components/Dialog.vue';
import draggable from 'vuedraggable'
export default {
  name: 'Flow',
  components: {
    AlertCircleOutline,
    ArrowRight,
    Delete,
    SquareEditOutline,
    ModelModal,
    Dialog,
    StationsModal,
    draggable
  },
  data() {
    return{
      flow: [],
      stations: [],
      tests: [],
      loading: true,
      error: false,
      modelModalVisible: false,
      currentModelIndex: 0,

      confirmDeleteVisible: false,
      confirmDeleteDesc: '',

      addStationVisible: false
    }
  },
  mounted() {
     
      this.axios
      .get('/stations')
      .then(response => {
          this.stations = response.data;
          this.axios
          .get('/flow/'+this.$route.params.id)
          .then(response => {
              this.flow = response.data;
              for(let i = 0; i < this.flow.nodes.length; i++)
              {
                for(let x = 0; x < this.stations.length; x++)
                {
                  if (this.flow.nodes[i].stationID == this.stations[x].stationID)
                  {
                    this.flow.nodes[i].name = this.stations[x].name;
                    break;
                  }
                }
              }
              this.axios
              .get('/tests')
              .then(response => {
                  this.tests = response.data;
                  for(let i = 0; i < this.flow.nodes.length; i++)
                  {
                    for(let x = 0; x < this.tests.length; x++)
                    {
                      if (this.flow.nodes[i].testID == this.tests[x].testID &&
                          this.flow.nodes[i].testVersion == this.tests[x].version)
                      {
                        this.flow.nodes[i].testName = this.tests[x].name;
                        break;
                      }
                    }
                  }

                  this.loading = false;
              })
              .catch(err => {
                  console.error(err);
              });
          })
          .catch(err => {
              console.error(err);
          });

      })
      .catch(err => {
          console.error(err);
          this.loading = false;
          this.error = true;
      });
  },
  methods: {
    save: function (event) {
     this.axios.post('/flow/update', this.flow)
          .then(response => {
              if(response.data.error != null){
                this.error = response.data.error;
                document.getElementById("error").style.opacity = '1';
              }
              else{
                var button = document.getElementsByClassName("save")[0];
                button.disabled = true;
                button.innerHTML = "Changes saved";
              }
              
          })
          .catch(err => {
              console.error(err);
          });
    },
    confirmDeleteClosed(){
      this.confirmDeleteVisible = false;
    },
    confirmDelete(){
      this.confirmDeleteVisible = false;
      this.flow.allowedModels.splice(this.currentModelIndex, 1);
      this.enableSaving();
    },
    enableSaving(){
      var button = document.getElementsByClassName("save")[0];
      button.disabled = false;
      button.innerHTML = "Save changes";
    },
    modelDeleted(){
      this.modelModalVisible = false;
      this.confirmDeleteDesc = 'Do you really want to delete \'' + this.flow.allowedModels[this.currentModelIndex].model + '\'?';
      this.confirmDeleteVisible = true;
    },
    modelClosed(){
      this.modelModalVisible = false;
    },
    editModel(index){
      this.currentModelIndex = index;
      this.modelModalVisible = true;
    },
    addModel(){
      let model = {'model': '','variables': ''};
      this.flow.allowedModels.push(model);
      this.currentModelIndex = this.flow.allowedModels.length-1;
      this.modelModalVisible = true;
    },
    addStation(){
      this.addStationVisible = true;
    },
    stationListClosed(){
      this.addStationVisible = false;
    },
    stationAdded(station){
      let tmpNode = {
        'stationID': station.stationID,
        'name': station.name,
        'testID': '',
        'testVersion': 0
      }
      this.flow.nodes.push(tmpNode);
      this.addStationVisible = false;
      this.enableSaving();
    },
    removeStation(index){
      this.flow.nodes.splice(index, 1);
      this.enableSaving();
    },
    onChangeTest(index, event){
      let test = this.tests[event.target.selectedIndex];
      this.flow.nodes[index].testID = test.testID;
      this.flow.nodes[index].testVersion = test.version;
      this.enableSaving();
    }
  }
}
</script>
<style scoped>
select{
  margin-top: 0;
  padding: 0;
  background-color: #67995b;
  border: 0;
  color: white;
  text-align: center;
  box-shadow: 0;
}
.save{
  float: right;
  margin-left: 5px;
}
.arrow{
  height: 40px;
  font-size: 30px;
  text-align: center;
  color: #5e5e5e;
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
}
.node{
  background-color: #a6a6a6;
  color: white;
  min-width: 200px;
  height: 100px;
  border-radius: 3px;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.active{
  background-color: #80bd71;
}

.nodeItem{
  background-color: #868686;
  padding: 5px;
  margin-top: 5px;
}
.nodeItemActive{
  background-color: #67995b;
}
.left{
  float: left;
  padding-top: 45px;
}
.right{
  width: inherit;
  height: 30px;
}

.button{
  display: inline-block;
  margin-right: 5px;
}

.buttonDelete{
  margin-top: 5px;
  background-color: transparent;
}
.buttonDelete:hover{
  background-color: #67995b;
}

.buttonRight{
  float: right;
}

.buttonModel{
  background-color: #5e5e5e;
}
.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 25px;
}
.CRADLE{
  background-image: url('/assets/bamboo_cradle_black.png');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  height: 150px;
  width: 200px;
  display: inline-block;
}
.CRADLE div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding: 2px;
}
.LEFT{
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 150px;
  display: inline-block;
  margin-right: 10px;
  background-image: url('/assets/bamboo_earbud_left.png');
  background-position-x: -20px;
}
.RIGHT{
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 150px;
  display: inline-block;
  margin-right: 10px;
  background-image: url('/assets/bamboo_earbud_right.png');
  background-position-x: -95px;
}

.LEFT div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.RIGHT div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.FAIL{
  background-color: #bd7171;
}

.PASS {
  background-color: #67995b;
}

.BLUE{
  background-image: url('/assets/bamboo_cradle_black.png');
}

.RED{
  background-image: url('/assets/bamboo_cradle_red.png');
}

.BLACK{
  background-image: url('/assets/bamboo_cradle_black.png');
}

.CUSTOM{
  background-image: url('/assets/bamboo_cradle_custom.png');
}
</style>
