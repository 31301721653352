<template>
    <div class="topBar">
        <div class="topBarContent">
            <div class="logo"></div>

            <NavigationItem title="Overview" v-bind:icon="true">
                <router-link to="/" tag="div">Statistics</router-link>
            </NavigationItem>
            <NavigationItem title="Production Orders" v-bind:icon="true">
                <router-link to="/orders" tag="div">Overview</router-link>
                <router-link to="/planning" tag="div">Planning</router-link>
            </NavigationItem>
            <NavigationItem title="Tests" v-bind:icon="true">
                <router-link to="/tests" tag="div">Manage</router-link>
                <router-link to="/results" tag="div">Results</router-link>
            </NavigationItem>
            <NavigationItem title="Flows" v-bind:icon="true">
                <router-link to="/flows" tag="div">Manage</router-link>
            </NavigationItem>
            <NavigationItem title="Stations" v-bind:icon="true">
                <router-link to="/stations" tag="div">Manage</router-link>
            </NavigationItem>

            <NavigationItem v-bind:title="$root.session.name" v-bind:right="true" v-bind:icon="true" v-if="$root.session.userID">
                <!-- <router-link to="/settings" tag="div">Settings</router-link> -->
                <div v-on:click="logout()">Logout</div>
            </NavigationItem>

        </div>

    </div>
</template>

<script>
    import NavigationItem from "@/components/NavigationItem";

    export default {
        name: "Navigation",
        components: {
            NavigationItem
        },
        methods:{
            logout(){
                document.cookie = 'Session-Id = ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
                window.location = '/';
            }
        }
    }
</script>

<style scoped>
    .topBar{
        background-color: #5c7080;
        box-shadow: 0 1px 0 #414f5a;
        text-align: center;
        width: 100%;
        height: 55px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 100;
    }
    .topBarContent{
        text-align: left;
        display: inline-block;
        max-width: 1524px;
        width: 100%;
        height: 55px;
    }
    .logo{
        display: inline-block;
        background-image: url(~@/assets/Logo.svg);
        background-repeat: no-repeat;
        background-size: 75px 55px;
        background-position-y: -6.5px;
        background-position-x: -14px;
        width: 55px;
        height: 55px;
    }
</style>