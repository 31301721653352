<template>
  <div>
    
    <Dialog :desc="deleteModalDesc" 
    v-bind:visible="deleteModalVisible" 
    @closed="deleteModalClosed"
    @yes="deleteModalConfirm"/>

    <div class="lds-dual-ring" v-if="loading && !error"></div>
    <div v-if="error" class="error"><AlertCircleOutline/> Tests not found</div>
    
    <div class="content" v-if="!loading">
      <div class="bannertitle" v-if="!loading">Manage tests</div>

      <button class="button newtest" v-on:click="createTest">Create test</button>

     <Table :columns="['Version', 'Name', 'Date', 'Delete']" :items="tests" v-if="!loading" v-slot="{row}">
        <td><div class="tag">V{{ row.version }}</div></td>
        <td>{{ row.name }}</td>
        <td>{{ row.date }}</td>
        <td class="tmenu">
          <button class="button delete" v-on:click="deleteTest($event, row.testID, row.version, row.name)"><Delete/> Delete</button>
        </td>
     </Table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import Table from "@/components/Table"
import Dialog from '../components/Dialog.vue';

export default {
  name: 'Tests',
  components: {
    Table,
    AlertCircleOutline,
    Delete,
    Dialog
  },
  data() {
    return{
      tests: [],
      loading: true,
      error: false,

      deleteModalDesc: "",
      deleteModalVisible: false,
      deleteTestID: "",
      deleteVersion: 0,
    }
  },
  mounted() {
     this.loadTests();
  },
  methods:{
    loadTests(){
      this.axios
      .get('/tests')
      .then(response => {
          this.tests = response.data;
          for (var i = 0; i < this.tests.length; i++) {
            this.tests[i].url = 'test/'+this.tests[i].testID+'/'+this.tests[i].version;
          }
          this.loading = false
      })
      .catch(err => {
          this.error = true;
      });
    },
    createTest(){
      var newTest = {}
      newTest.name = "New Test";
      newTest.steps = [];
      newTest.testID = ''; 
      newTest.version = 1;
      newTest.userID = '';
      this.axios.post('/test/create', newTest)
        .then(response => {
            if(response.data.error != null){
              this.error = response.data.error;
              document.getElementById("error").style.opacity = '1';
            }
            else{
              this.$router.push({ path: `/test/${response.data.testID}/${response.data.version}` })
              this.$router.go()
            }
        })
        .catch(err => {
            console.error(err);
      });
    },
    deleteTest(evt, testID, version, testName){
      evt.preventDefault();
      this.deleteTestID = testID;
      this.deleteVersion = version;
      this.deleteModalDesc = "Do you really want to permanently delete '" + testName + "'?";
      this.deleteModalVisible = true;
    },
    deleteModalConfirm(){
      this.deleteModalVisible = false;
       this.axios
        .get('/test/'+this.deleteTestID+'/'+this.deleteVersion+'/delete')
        .then(response => {
            this.loadTests();
        })
        .catch(err => {
            this.loading = false;
            this.error = 'Could not delete test';
        });
    },
    deleteModalClosed(){
        this.deleteModalVisible = false;
    }
  }
}
</script>
<style scoped>
.delete{
  min-width: 20px;
  margin-right: 2px;
  background-color: transparent;
  color: #bd7171;
}
.delete:hover{
background-color: #bd7171;
color: white;
}
.edit{
  min-width: 20px;
  margin-right: 2px;
  background-color: transparent;
  color: #5e5e5e;
}
.edit:hover{
background-color: #5e5e5e;
color: white;
}
.tag{
  display: inline-block;
  margin-top: 5px;
}
.tmenu{
   width: 70px;
}
.newtest{
  float: right;
}

.FAIL{
  background-color: #bd7171;
  min-width: 50px;
}

.PASS {
  background-color: #67995b;
  min-width: 50px;
}
</style>
