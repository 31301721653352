<template>
  <div>
  <div class="banner">
            <div class="bannercontent">
                <div class="logo"></div>
                <div class="bannertitle">Statistics</div>
                <div class="bannerdesc">View testresult statistics for a given date or date range
                </div>
                <date-range-picker 
                v-model="pickerDates"
                singleDatePicker="range"
                :ranges=false
                @update="dateChanged">
                  <template
                    v-slot:input="picker" 
                    style="min-width: 350px;">{{ pickerDates.startDate | date }} - {{ pickerDates.endDate | date }}
                  </template>
                </date-range-picker>
                <br>
            </div>
        </div>
      
      <div class="lds-dual-ring" v-if="loading && !error"></div>
      <div v-if="error" class="error"><AlertCircleOutline/> There are no statistics for this date range</div>

      <div class="content" v-if="!loading && !error">
        <div class="block">
          <div class="title">Total tests</div>
          <div class="number">{{results.PASS+results.FAIL}}</div>
        </div>

        <div class="block">
          <div class="title">Total failed tests <div class="circle" style="background-color: #d96464"/></div>
          <div class="number">{{results.FAIL}}</div>
        </div>

        <div class="block">
          <div class="title">Total passes</div>
          <div class="number">{{results.PASS}}</div>
        </div>

        <div class="block">
          <div class="title">First time pass <div class="circle" style="background-color: #428f57"/></div>
          <div class="number">{{results.PASS - results.passedAfterRetry}}</div>
        </div>

        <div class="block">
          <div class="title">Passed after retest <div class="circle" style="background-color: #53b06c"/></div>
          <div class="number">{{results.passedAfterRetry}}</div>
        </div>

        <div :set="failPercentage=Math.round((100/(results.FAIL+results.PASS)*results.FAIL)*100)/100"/>
        <div :set="firstPassPercentage=Math.round((100/(results.FAIL+results.PASS))*(results.PASS-results.passedAfterRetry)*100)/100"/>
        <div :set="retestPassPercentage=Math.round((100/(results.FAIL+results.PASS)*results.passedAfterRetry)*100)/100"/>

        <div class="graphBlock">
          <div class="graphBlockPart" style="background-color: #d96464" :style="` width: ${failPercentage}%`">{{failPercentage}}%</div>
          <div class="graphBlockPart" style="background-color: #428f57" :style="` width: ${firstPassPercentage}%`">{{firstPassPercentage}}%</div>
          <div class="graphBlockPart" style="background-color: #53b06c" :style="` width: ${retestPassPercentage}%`">{{retestPassPercentage}}%</div>
        </div>
        <div class="bannertitle">Fail reasons</div>
        <div class="reasons">
          <div class="item" v-for="(count, index) in results.reasons" v-bind:key="index"><b>{{count[1]}}</b> {{count[0]}}</div>
        </div>
      </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'

export default {
  name: 'Home',
  components: {
    DateRangePicker,
    AlertCircleOutline
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();

    return {
      pickerDates: {
        startDate,
        endDate
      },
      loading: true,
      error: false,
      results: {}
    };
  },
  mounted() {
    this.dateChanged();
    this.timer = setInterval(() => {
    this.dateChanged(false);
  }, 60000)
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat("nl-NL").format(date);
    }
  },
   methods:{
    dateChanged(loading=true) {
      this.loading = loading;
      this.axios.post('/statistics', {start: new Intl.DateTimeFormat("nl-NL").format(this.pickerDates.startDate),
                                      end:  new Intl.DateTimeFormat("nl-NL").format(this.pickerDates.endDate)})
      .then(response => {
         this.results = response.data;
         this.loading = false;
         if(this.results.error != null)
         {
          this.error = true;
         }
         else
         {
          this.error = false;
         }
      })
      .catch(err => {
          this.error = true;
      });
    }
   }
}
</script>

<style scoped>
.reasons{
  background-color: white;
  display: inline-block;
  margin-top: 5px;
  border-radius: 3px;
  padding: 5px;
  text-align: left;
  width: 1005px;
  border: 1px solid #ccc;
}

.reasons .title{
  color: #1d1d1d;
  font-weight: bold;
}

.reasons .item{
  border-bottom: 1px solid #ccc;
  padding: 5px;
}

.reasons .item:last-child{
  border-bottom: 0px;
}


.circle{
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: green;
  display: inline-block;
  float: right;
  margin-left: -10px;
}
.graphBlock{
  background-color: white;
  border-radius: 5px;
  text-align: left;
  width: 1016px;
  margin-top: 5px;
  border: 1px solid #ccc;
  height: 25px;
  white-space: nowrap;
}
.graphBlockPart{
  background-color: white;
  width: 0%;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  color: white;
}

.graphBlockPart:first-child{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.graphBlockPart:last-child{
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.block{
  background-color: white;
  display: inline-block;
  margin-right: 5px;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  width: 18.3%;
  border: 1px solid #ccc;
}

.block .title{
  color: #1d1d1d;
  font-weight: bold;
}

.block .number{
  font-size: 25px;
  color: #575757;
}
</style>
