<template>
  <div>
    <Modal desc='Select action to add' v-bind:actions="this.actions" v-bind:visible="this.actionModalVisible" 
    @addAction="addAction" 
    @closed="addActionClosed" />

    <Dialog v-bind:desc="this.confirmDeleteStepDesc"
    v-bind:visible="this.confirmDeleteStepVisible" 
    @closed="confirmDeleteStepClosed"
    @yes="confirmDeleteStep"/>

    <div class="lds-dual-ring" v-if="loading"></div>
    <div v-if="error" class="error" id="error"><AlertCircleOutline/> {{this.error}}</div>
    <div class="banner" v-if="!loading && !error">
        <div class="bannercontent">
          <div class="left">
            <div class="logo"></div>
            <input class="testname" :value="test.name" v-bind:disabled="test.executed" @change="nameChanged($event)" />
            <div class="bannerdesc">{{test.date}}</div>
            <select @change="onVersionChange($event)" class="tag versionselector">
              <option v-for="testversion in tests" :key="testversion.version" :value="testversion.testID" :version="testversion.version" :selected="testversion.testID == test.testID && testversion.version == test.version">V{{testversion.version}}</option>
            </select>
            <div class="bannerdesc" v-if="test.executed"><AlertCircleOutline/> This test has been executed and cannot be edited or deleted</div>
          </div>
        </div>
      </div>
    
    <div class="content" v-if="!loading && !error">
      <button class="button save" v-on:click="save" disabled>Changes saved</button>
      <button class="button newtest" v-on:click="addStep" :disabled="test.executed">Add step <Plus/></button>
      <button class="button newversion" v-on:click="createCopy">New version</button>
      <br><br>
      <div class="bannerdesc">Model specific variables can be used as parameters by typing <code>$</code> followed by the variable name. For example <code>$MIN_VERSION</code></div>
      
      <EditTestStepItem v-for="item in test.steps" :key="item.stepNR" v-bind:step="item" v-bind:enabled="!test.executed"
        v-bind:actionModalVisible="actionModalVisible" 
        @showActionModal="showActionModal" 
        @actionOrderChanged="actionOrderChanged"
        @argumentChanged="argumentChanged"
        @deleteAction="deleteAction"
        @stepDescChanged="stepDescChanged"
        @deleteStep="deleteStep"
        @uploadImage="uploadImage"/>
    <div v-if="test.steps.length == 0 && !loading && !error" class="nosteps">
      <AlertCircleOutline/> This test is empty <br>  
    </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import EditTestStepItem from "@/components/EditTestStepItem";
import Modal from "@/components/Modal";
import Plus from 'vue-material-design-icons/Plus.vue'
import Dialog from '../components/Dialog.vue';
export default {
  name: 'Result',
  components: {
   EditTestStepItem,
   Modal,
   AlertCircleOutline,
   Plus,
   Dialog
  },
  data() {
    return{
      test: [],
      tests: [],
      serverTest: [],
      actions: [],
      loading: true,
      error: false,

      actionModalVisible: false,
      actionModalStepID: 0,

      confirmDeleteStepVisible: false,
      confirmDeleteStepDesc: '',
      confirmDeleteStepID: 0
    }
  },
  mounted() {
      this.axios
      .get('/test/'+this.$route.params.id+'/'+this.$route.params.version)
      .then(response => {
          this.serverTest = response.data;
          this.test = response.data
          this.axios
          // Load test versions
          .get('/tests/'+this.test.testID)
          .then(response => {
              this.tests = response.data;
              this.loading = false
          })
          .catch(err => {
              this.loading = false;
              this.error = 'Could not load information';
          });
      })
      .catch(err => {
          console.error(err);
          this.loading = false;
          this.error = 'Could not load information';
      });

      this.axios
      .get('/testactions')
      .then(response => {
          this.actions = response.data;
      })
      .catch(err => {
          console.error(err);
          this.loading = false;
          this.error = 'Could not load information';
      });

  },
  methods:{
    save: function (event) {
     this.axios.post('/test/update', this.test)
          .then(response => {
              if(response.data.error != null){
                this.error = response.data.error;
                document.getElementById("error").style.opacity = '1';
              }
              else{
                var button = document.getElementsByClassName("save")[0];
                button.disabled = true;
                button.innerHTML = "Changes saved";
              }
              
          })
          .catch(err => {
              console.error(err);
          });
    },
    remove(){

    },
    refreshActionOrder(stepNR){
    for (let i = 0; i < this.test.steps[stepNR].actions.length; i++) { 
        this.test.steps[stepNR].actions[i].actionNR = i;
      }
    },
    addStep(){
      var tmpStep = {};
      tmpStep.description = "Step " + (this.test.steps.length + 1);
      tmpStep.actions = [];
      tmpStep.stepNR = this.test.steps.length;
      tmpStep.testID = this.test.testID;
      tmpStep.version = this.test.version;
      this.test.steps.push(tmpStep);
      this.enableSaving();
    },
    showActionModal(stepID) {
        this.actionModalVisible = true;
        this.actionModalStepID = stepID
    },
    enableSaving(){
      var button = document.getElementsByClassName("save")[0];
      button.disabled = false;
      button.innerHTML = "Save changes";
    },
    addAction(action) {
        this.actionModalVisible = false;

        var tmpAction = {};
        tmpAction.action = action;
        tmpAction.actionID = action.actionID;
        tmpAction.actionNR = this.test.steps[this.actionModalStepID].actions.length;
        tmpAction.parameters = '';
        tmpAction.result = null;
        tmpAction.stepNR = this.actionModalStepID;
        tmpAction.testID = this.test.testID;
        tmpAction.version = this.test.version;
        tmpAction.image = ''

        this.test.steps[this.actionModalStepID].actions.push(tmpAction);
        this.enableSaving();
    },
    uploadImage(stepNR, actionIndex, base64){
      this.test.steps[stepNR].actions[actionIndex].image = base64;
      this.enableSaving();
    },
    addActionClosed(){
      this.actionModalVisible = false;
    },
    actionOrderChanged(evt){
      this.refreshActionOrder(evt.moved.element.stepNR);
      this.enableSaving();
    },
    argumentChanged(stepNR, actionIndex, paramIndex, data){
      var currentParams = this.test.steps[stepNR].actions[actionIndex].parameters.split(',');
      currentParams[paramIndex] = data;
      this.test.steps[stepNR].actions[actionIndex].parameters = currentParams.join(',');
      this.enableSaving();
    },
    deleteAction(stepNR, actionIndex){
      this.test.steps[stepNR].actions.splice(actionIndex, 1);
      this.enableSaving();
    },
    deleteStep(stepNR){
      this.confirmDeleteStepDesc = 'Do you really want to delete the step \'' + this.test.steps[stepNR].description + '\'?';
      this.confirmDeleteStepVisible = true;
      this.confirmDeleteStepID = stepNR;
    },
    stepDescChanged(stepNR, desc){
      this.test.steps[stepNR].description = desc;
      this.enableSaving();
    },
    confirmDeleteStepClosed(){
      this.confirmDeleteStepVisible = false;
    },
    confirmDeleteStep(){
      this.confirmDeleteStepVisible = false;
      var stepNR = this.confirmDeleteStepID;
      this.test.steps.splice(stepNR, 1);
      for (let i = 0; i < this.test.steps.length; i++) { 
        this.test.steps[i].stepNR = i;
        for (let x = 0; x < this.test.steps[i].actions.length; x++) { 
          this.test.steps[i].actions[x].stepNR = i;
        }
      }
      this.enableSaving();
    },
    createCopy(){
      this.axios.post('/test/create', this.test)
        .then(response => {
            if(response.data.error != null){
              this.error = response.data.error;
              document.getElementById("error").style.opacity = '1';
            }
            else{
              this.$router.push({ path: `/test/${response.data.testID}/${response.data.version}` })
              this.$router.go()
            }
        })
        .catch(err => {
            console.error(err);
      });
    },
    deleteCopy(){

    },
    onVersionChange(event) {
      var testID = event.target.value;
      var version = event.target.options[event.target.selectedIndex].getAttribute("version");
      this.$router.push({ path: `/test/${testID}/${version}` })
      this.$router.go()
    },
    nameChanged(evt){
      this.test.name = evt.target.value;
      this.enableSaving();
      evt.target.blur();
    }
}
}
</script>
<style scoped>
.left{
  float: left;
  padding-top: 10px;
  width: 100%;
}
.right{
  float: right;
}
.newtest{
  float: right;
  min-width: 30px;
}
.save{
  float: right;
  margin-left: 5px;
}
.newversion{
  float: left;
}
.deleteversion{
  margin-left: 5px;
  background-color: rgb(194, 58, 58);
}
.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 25px;
}
.testname{
  display: inline-block;
  background-color:  transparent;
  color: #4b4b4b;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 0px;
  width: 100%;
  height: 15px;
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 22px;
}

.testname:hover{
  border-color: #aaa;
}
.versionselector{
  border: 0;
 box-shadow: none;
 border-radius: 3px;
 margin: 0;
}
.nosteps{
  text-align: center;
  color: #5e5e5e;
  padding: 10px;
}
</style>
